import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取新手教学列表
export function getNoviceTeachingList() {
  return request({
    url: `/admin/noviceTeaching/getListForList/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}
