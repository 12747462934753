<template>
  <div class="main">
    <h1 class="h1">{{ $t('NoviceTeaching') }}</h1>
    <el-button type="primary" @click="getManual">
      UNU Travel平台使用教學手冊
    </el-button>
    <template v-if="videoList&&videoList.length">
      <tabs v-model="activeName" @tab-click="onChange">
        <tab-pane v-for="item of videoList"
                  :key="item.id"
                  :label="item.defaultTitle"
                  :name="item.id"></tab-pane>
      </tabs>
      <video-player class="player"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
      </video-player>
    </template>
  </div>
</template>
<script>
import 'video.js/dist/video-js.css';
import {videoPlayer} from 'vue-video-player';
import Tabs from '@/components/Tabs/Tabs';
import TabPane from '@/components/Tabs/TabPane';
import {getNoviceTeachingList} from '@/api/admin-teaching';

export default {
  name: 'TeachingVido',
  components: {Tabs, TabPane, videoPlayer},
  data() {
    return {
      activeName: '',
      playerOptions: {
        muted: false,
        controls: true,
        language: 'zh-CN',
        // playbackRates: [0.7, 1.0, 1.5, 2.0],  // 播放速率
        sources: []
        // ,hls: true,  //启用hls？
        // fluid: true,  //设置播放器为流体  宽度为外层盒子大小  ps：想设置width：100%找不到方法？这个就对了
        // poster: 'http://welove88.oss-cn-shenzhen.aliyuncs.com/20200608/17/bdbad642c9f449269f6d8006f3dcb8c8.jpg'
      },
      videoList: []
    };
  },
  created() {
    this.getNoviceTeachingList();
  },
  methods: {
    // Get Business Manual
    getManual() {
      window.open('https://unu-oss.oss-cn-hongkong.aliyuncs.com/businessManual/UNU%20Travel%20%E5%B9%B3%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%95%99%E5%AD%B8.pdf', '_blank');
    },
    // 获取新手教学列表
    getNoviceTeachingList() {
      getNoviceTeachingList().then(res => {
        const list = res.value || [];
        if (list.length) {
          const item = list[0];
          this.activeName = item.id;
          this.playerOptions.sources = [{type: 'video/mp4', src: item.videoUrl}];
          this.videoList = list;
        }
      });
    },
    onChange(value) {
      const item = this.videoList.find(item => item.id === value);
      this.playerOptions.sources = [{type: 'video/mp4', src: item.videoUrl}];
    }
  }
};
</script>
<style scoped lang="less">
.main{
  /deep/ .tab-nav{
    .item{width:auto;margin-right:36px;}
  }
}
.h1{font-size:24px;}
.player{
  position:relative;height:560px;margin-top:24px;background-color:#000;
  /deep/ .video-js{
    width:100%;height:100%;
    .vjs-big-play-button{top:50%;left:50%;width:2em;height:2em;margin:-1em 0 0 -1em;line-height:2em;border-radius:50%;}
  }
}
</style>
